import { request, noTimeOutReq } from '@/utils/request.js'

//查询历史批次集合
export function getBatchConditionApi (examId) {
  return request({
    url: `/call/batch/get/view/examinee/condition/${examId}`,
    method: 'post',
  })
}






