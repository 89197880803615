<template>
  <div id="header">
    <el-card shadow="always">
      <div>
        <el-button v-throttle type="primary" size="medium" @click="goBack"
          >返回</el-button
        >
      </div>
      <div id="exam-box">{{ urlParam.examName }}</div>
      <div id="text-box">考生信息一览</div>
      <div style="line-height: 36px">
        <el-row>
          <span>
            统计：当前已完成考试{{ censusInfo.haveExamExamineeCount }}人，
            未完成考试{{ censusInfo.notExamExamineeCount }}人， 共{{
              censusInfo.examExamineeCount
            }}人
          </span>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-form
            ref="form"
            :inline="true"
            :model="queryParam"
            label-width="80px"
          >
            <el-form-item label="考试状态">
              <el-select
                v-model="queryParam.examineeStatus"
                placeholder="请选择考试状态"
                @change="chooseExamineeFinishStatus"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="examStatus in examStatusOption"
                  :key="examStatus.value"
                  :label="examStatus.label"
                  :value="examStatus.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考生状态">
              <el-select
                v-model="queryParam.examineeFinishStatus"
                placeholder="请选择考生状态"
                @change="chooseExamineeStatus"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="examExamineeStatus in examExamineeStatusOption"
                  :key="examExamineeStatus.value"
                  :label="examExamineeStatus.label"
                  :value="examExamineeStatus.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考生学校">
              <el-select
                v-model="queryParam.examineeSchoolId"
                placeholder="请选择考生学校"
                @change="chooseExamSchool"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="examSchool in examSchoolOption"
                  :key="examSchool.sysOrgSchoolId"
                  :label="examSchool.name"
                  :value="examSchool.sysOrgSchoolId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考试批次">
              <el-select
                v-model="queryParam.examSession"
                placeholder="请选择考试批次"
                @change="chooseExamSession"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="examSession in examSessionOption"
                  :key="examSession"
                  :label="examSession"
                  :value="examSession"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考生编号">
              <el-input
                v-model="queryParam.examineeCode"
                placeholder="请输入考生编号"
                @input="chooseExamineeCode"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="考生姓名">
              <el-input
                v-model="queryParam.examineeName"
                placeholder="请输入考生姓名"
                @input="chooseExamineeName"
                clearable
              ></el-input>
            </el-form-item>
            <el-button
              v-throttle
              type="primary"
              icon="el-icon-search"
            ></el-button>
          </el-form>
        </el-row>
        <el-row>
          <el-table
            v-loading="loading"
            stripe
            border
            ref="userTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#DFE6EC',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="index"
              label="序号"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="examineeCode"
              label="考生编号"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeName"
              label="考生姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeSchoolName"
              label="考生学校"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeSchoolClassName"
              label="考生班级"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeFinishStatus"
              label="考生状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.examineeFinishStatus == 1">未点名</span>
                <span v-if="scope.row.examineeFinishStatus == 2">已点名</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="examineeStatus"
              label="考试状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.examineeStatus == 1">未完成</span>
                <span v-if="scope.row.examineeStatus == 2">已完成</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="urlParam.randomTimingType == 1"
              prop="subjectType"
              label="科目"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.subjectType == 1">物理</span>
                <span v-if="scope.row.subjectType == 2">生物</span>
                <span v-if="scope.row.subjectType == 3">化学</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="urlParam.randomTimingType == 2"
              prop="subjectTypeList"
              label="科目"
              show-overflow-tooltip
              :formatter="customFormatter"
            ></el-table-column>
            <el-table-column
              prop="sessionFinialTime"
              label="报到时间"
              show-overflow-tooltip
              :formatter="customFormatter"
            ></el-table-column>
            <el-table-column
              prop="examSession"
              label="考试批次"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.examSession">{{
                  scope.row.examSession
                }}</span>
                <span v-if="!scope.row.examSession">暂无批次</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="seatCode"
              label="座位号"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.seatCode">{{ scope.row.seatCode }}</span>
                <span v-if="!scope.row.seatCode">待分配</span>
              </template>
            </el-table-column>
          </el-table>
          <div id="pagination-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page.pageIndex"
              :page-sizes="[5, 8, 10]"
              :page-size="page.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.totalSize"
            ></el-pagination>
          </div>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  getBatchViewListApi,
  getExamineeCountApi,
} from '@/api/rollCall/rollCall.js'
import { getBatchConditionApi } from '@/api/rollCall/batchView.js'
import { isEmpty, formatDate } from '@/utils/util'
// import cryptoAES from '@/utils/crypto.js'
export default {
  components: {},
  name: 'moreBatchView',
  data() {
    return {
      queryParam: {
        examineeFinishStatus: '',
        examineeStatus: '',
        examineeSchoolId: '',
        examSession: '',
        examineeCode: '',
        examineeName: '',
      },
      examStatusOption: [
        { label: '未完成', value: 1 },
        { label: '已完成', value: 2 },
      ],
      examExamineeStatusOption: [
        { label: '未点名', value: 1 },
        { label: '已点名', value: 2 },
      ],
      loading: false,
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      urlParam: {
        examId: 0,
        examSession: 0,
        examPlaceId: 0,
        randomTimingType: 0,
        examSubjectId: 0,
        sysOrgExamPlaceRoomId: 0,
        examName: '',
        sysOrgExamPlaceName: '',
        chooseFlag: 0,
      },
      //统计信息
      censusInfo: {},
      tableData: [],
      examSchoolOption: [],
      examSessionOption: [],
      // 抽签方式（1报到即抽签 2报到后抽签）
      randomTimingDrawType: 1,
    }
  },
  mounted() {
    this.randomTimingDrawType = Number(this.$route.query.randomTimingDrawType)
    this.renderUrlParam()
  },
  methods: {
    renderUrlParam() {
      this.urlParam.examId = 0
      this.urlParam.examId = Number(this.$route.query.examId)
      this.urlParam.examSession = 0
      this.urlParam.examSession = Number(this.$route.query.examSession)
      this.urlParam.examPlaceId = 0
      this.urlParam.examPlaceId = Number(this.$route.query.examPlaceId)
      this.urlParam.randomTimingType = 0
      this.urlParam.randomTimingType = Number(
        this.$route.query.randomTimingType
      )
      // if (this.urlParam.randomTimingType == 1) {
      this.urlParam.examSubjectId = 0
      this.urlParam.examSubjectId = Number(this.$route.query.examSubjectId)
      this.urlParam.sysOrgExamPlaceRoomId = 0
      this.urlParam.sysOrgExamPlaceRoomId = Number(
        this.$route.query.sysOrgExamPlaceRoomId
      )
      this.urlParam.chooseFlag = 0
      this.urlParam.chooseFlag = Number(this.$route.query.chooseFlag)
      // }
      this.urlParam.examName = ''
      this.urlParam.examName = this.$route.query.examName
      this.urlParam.sysOrgExamPlaceName = ''
      this.urlParam.sysOrgExamPlaceName = this.$route.query.sysOrgExamPlaceName
      //查询考试考生汇总信息
      this.getExamineeCount()
      //获取表格所需数据
      this.getBatchViewList()
      //获取学校,批次
      this.getBatchCondition()
    },
    //查询考试考生汇总信息
    getExamineeCount() {
      getExamineeCountApi(this.urlParam.examId).then((res) => {
        if (res.success) {
          this.censusInfo = {}
          this.censusInfo = res.data
        } else {
          this.msgError('获取统计信息失败')
        }
      })
    },
    //获取表格所需数据
    getBatchViewList() {
      let returnData = {
        examineeFinishStatus: this.queryParam.examineeFinishStatus,
        examineeStatus: this.queryParam.examineeStatus,
        examineeSchoolId: this.queryParam.examineeSchoolId,
        examSession: this.queryParam.examSession,
        examineeCode: this.queryParam.examineeCode,
        examineeName: this.queryParam.examineeName,
        randomTimingType: this.urlParam.randomTimingType,
      }
      this.loading = true
      getBatchViewListApi(
        this.urlParam.examId,
        this.page.pageIndex,
        this.page.pageSize,
        returnData
      ).then((res) => {
        this.loading = false
        if (res.success) {
          this.tableData = []
          this.tableData = res.data
          this.page.totalSize = 0
          this.page.totalSize = res.total
          this.tableData.forEach((item, index) => {
            this.$set(item, 'index', index + 1)
          })
        }
      })
    },
    //获取学校, 批次
    getBatchCondition() {
      getBatchConditionApi(this.urlParam.examId).then((res) => {
        if (res.success) {
          this.examSchoolOption = []
          this.examSchoolOption = res.data.sysOrgSchoolList
          this.examSessionOption = []
          this.examSessionOption = res.data.examSessionList
        }
      })
    },
    //查询参数筛选
    chooseExamineeFinishStatus() {
      this.renderTableData()
    },
    chooseExamineeStatus() {
      this.renderTableData()
    },
    chooseExamSchool() {
      this.renderTableData()
    },
    chooseExamSession() {
      this.renderTableData()
    },
    chooseExamineeCode() {
      this.renderTableData()
    },
    chooseExamineeName() {
      this.renderTableData()
    },
    renderTableData() {
      this.page.pageIndex = 1
      this.getBatchViewList()
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getBatchViewList()
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getBatchViewList()
    },
    goBack() {
      switch (this.urlParam.randomTimingType) {
        case 1:
          this.$router.push({
            path: '/rollCall/separateRollCall',
            query: {
              examId: this.urlParam.examId,
              examSession: this.urlParam.examSession,
              examPlaceId: this.urlParam.examPlaceId,
              randomTimingType: this.urlParam.randomTimingType,
              examSubjectId: this.urlParam.examSubjectId,
              sysOrgExamPlaceRoomId: this.urlParam.sysOrgExamPlaceRoomId,
              chooseFlag: this.urlParam.chooseFlag,
              randomTimingDrawType: this.randomTimingDrawType,
            },
          })
          break
        case 2:
          this.$router.push({
            path: '/rollCall/centralizeRollCall',
            query: {
              examId: this.urlParam.examId,
              examPlaceId: this.urlParam.examPlaceId,
              randomTimingType: this.urlParam.randomTimingType,
              randomTimingDrawType: this.randomTimingDrawType,
              sysOrgExamPlaceRoomId: this.urlParam.sysOrgExamPlaceRoomId,
            },
          })
          break
      }
    },
    //自定义格式内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'subjectTypeList':
          let appendReturn = ''
          row.subjectTypeList.forEach((item, index) => {
            let subjectName = ''
            if (isEmpty(item)) {
              return
            }
           let key = Number(item)
            switch (key) {
              case 1:
                subjectName = '物理'
                break
              case 2:
                subjectName = '生物'
                break
              case 3:
                subjectName = '化学'
                break
            }
            appendReturn += subjectName
            if (index < row.subjectTypeList.length - 1) {
              appendReturn += ','
            }
          })
          return appendReturn
        case 'sessionFinialTime':
          if (isEmpty(row.sessionFinialTime)) {
            return '未报到'
          }
          return formatDate(
            new Date(row.sessionFinialTime),
            'yyyy-MM-dd hh:mm:ss'
          )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#header {
  padding: 0 10px;
  margin-top: 10px;
}

::v-deep .el-card__body {
  padding: 15px;
}

#exam-box {
  font-weight: bold;
  font-size: 20px;
}

#text-box,
#examination-room-box {
  margin-top: 10px;
  font-size: 16px;
}

#exam-box,
#text-box,
#examination-room-box {
  text-align: center;
}

#pagination-box {
  margin-top: 15px;
}

::v-deep .el-form-item__label {
  background-color: #dfe6ec;
  text-align: center;
  padding: 0;
}
</style>
